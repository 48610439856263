import React, { useEffect } from "react";
import LastExit from "../assets/LastExit.png";
import LastExitLogo from "../assets/LastExitLogo.png";

import Signature from "../assets/Signature.png";
import SignatureLogo from "../assets/SignatureLogo.png";

import Metropolis from "../assets/Metropolis.jpg";
import MetropolisLogo from "../assets/MetropolisLogo.png";

import AOS from "aos";
import "aos/dist/aos.css";

function Projects() {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<div id="Projects" className="relative overflow-hidden my-[5%] flex flex-col justify-center align-center items-center px-[5%]">
			<h1
				data-aos="fade-down"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="1000"
				data-aos-delay="100"
				className="text-[#135168] text-center font-playfair text-3xl md:text-4xl lg:text-5xl font-semibold leading-[188.235%]"
			>
				Upcoming Projects
			</h1>
			<div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2  xl:grid-cols-2  gap-4 m-[5%] mx-[0%]">
				{[
					{
						Logo: LastExitLogo,
						background: LastExit,
						Type: "Upcoming",
						Heading: "Last Exit",
						Text: "Last Exit, situated on Super Highway M-9 in Karachi, is a premier residential and commercial development with a project cost of 6000 million. This modern and luxurious project offers state-of-the-art amenities and innovative design, making it an ideal choice for both living and business purposes.",
						mainClasses:
							"lg:col-span-2  md:col-span-2   xl:col-span-2 h-[65vh] lg:h-[70vh] bg-center gap-2 px-[50px] ",
						TextClasses: "lg:w-[50%] text-lg font-normal leading-[180%] mb-[2%] ",
						headingClasses: "text-3xl md:text-5xl lg:text-6xl font-semibold leading-[157.377%] ",
						LogoStyle: "w-[80px] h-[65px] flex-shrink-0",
						link: "http://lastexit.pk/",
					},

					{
						Logo: SignatureLogo,
						background: Signature,
						Type: "Ongoing Project",
						Heading: "Metropolis  Signature",
						Text: "Metropolis Signature is a prestigious mixed-use development in Karachi, featuring luxurious residential and modern commercial spaces. With a project cost of 5000 million,  setting a new standard for sophisticated urban living and business environments.",
						TextClasses: "text-xs lg:text-sm font-normal",
						headingClasses:
							"text-[25px] lg:text-[30px] font-semibold leading-[40px] ",
						LogoStyle: "w-[60px] h-[45px]  flex-shrink-0 ",
						link: "https://metropolissignature.com.pk/",
						mainClasses: "gap-4 h-[50vh] "
					},
					{
						Logo: MetropolisLogo,
						background: Metropolis,
						Type: "Ongoing Project",
						Text: "Metropolis is a prestigious mixed-use development in Karachi, blending residential and commercial spaces. With a project cost of 8000 million, it offers luxurious amenities and modern design, setting a new standard for urban living and business in the city.",
						Heading: "Metropolis",
						TextClasses: "text-xs lg:text-sm font-normal",
						headingClasses:
							"text-[25px] lg:text-[30px] font-semibold leading-[40px] ",
						LogoStyle: "w-[90px] h-[45px]  flex-shrink-0 ",
						link: "https://metropolis.com.pk/",
						mainClasses: "gap-4 h-[50vh] "
					}
				].map((a, i) => (
					<div
						data-aos="fade-down"
						data-aos-offset="200"
						data-aos-easing="ease-in-sine"
						data-aos-duration="1000"
						data-aos-delay={100 * (i + 1)}
						className={
							a?.mainClasses +
							"shadow-dark-10 w-full flex flex-col bg-no-repeat bg-cover rounded-[20px] md:rounded-[50px] py-[30px] px-[20px] bg-lightgray "
						}
						style={{
							backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url('${a?.background}')`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover"
						}}
					>
						<div className="flex flex-row justify-between align-center">
							<p className="flex justify-center align-center items-center uppercase text-white font-poppins bg-[#000] text-xs font-bold  px-[30px] py-[0px] leading-[100%] rounded-full">
								{a?.Type}
							</p>
							<img src={a?.Logo} className={a?.LogoStyle} />
						</div>
						<p className={a?.headingClasses + "  text-white font-poppins "}>{a?.Heading}</p>
						<p className={a?.TextClasses + "  text-white font-poppins "}>{a?.Text}</p>
						<a href={a?.link} target="_blank" className="shadow-[0_50px_50px_rgba(0,0,0,0.8)] flex w-full md:w-[135px] p-[10px] lg:p-[20px] justify-center items-center gap-10 rounded-[14px] border-[0px] border-solid border-black bg-white text-black text-center font-work-sans text-base font-semibold">
							Learn More{" "}
						</a>
					</div>
				))}
			</div>
		</div>
	);
}

export default Projects;
