import Slider from "./Components/Slider";
import Banner from "./Components/Banner";
import Header from "./Components/Header";
import AboutUs from "./Components/AboutUs";
import Why from "./Components/Why";
import Mission from "./Components/Mission";
import Projects from "./Components/Projects";
import Vision from "./Components/Vision";
import Pinnacale from "./Components/Pinnacale";
import Authorities from "./Components/Authorities";
import Message from "./Components/Message";
import Query from "./Components/Query";
import Footer from "./Components/Footer";
function App() {
	return (
		<div className="flex flex-col">
			<Header />
			<Banner />
			<Slider />
			<AboutUs />
			<Why />
			<Mission />
			<Projects />
			<Vision />
			<Pinnacale />
			<Authorities />
			<Message />
			<Query />
			<Footer />
		</div>
	);
}

export default App;
