import React, { useEffect } from "react";
import Image from "../assets/image 13.png";
import Image2 from "../assets/Polygon 2 (1).png";

import AOS from "aos";
import "aos/dist/aos.css";

function Vision() {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<div
			className="relative overflow-hidden  flex flex-row justify-center align-center items-center gap-0 h-[100vh] md:h-[70vh] xl:h-[50vh]  lg:h-[50vh] bg-no-repeat bg-cover bg-center "
			style={{
				background: `url('${Image}')`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover"
			}}
		>
			<img
				data-aos="fade-left"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="1000"
				data-aos-delay="2000"
				className="w-[0%] md:w-[15%] h-[80%]"
				src={Image2}
			/>
			<div
				data-aos="fade-down"
				data-aos-offset="200"
				data-aos-easing="ease-in-sine"
				data-aos-duration="1000"
				data-aos-delay="1000"
				className="w-[85%] flex flex-col gap-6 overflow-hidden items-start justify-center h-[100%] px-[10px] xl:px-[80px] lg:px-[80px] md:px-[30px] sm:px-[20px]"
			>
				<p className="text-white font-playfair text-2xl md:text-3xl lg:text-4xl font-semibold leading-[188.235%]">
					Crafting Your Vision into Reality{" "}
				</p>

				<p className="text-white font-work-sans text-xs md:text-sm lg:text-md font-normal">
					At Motiwala Builders, our vision is to be the leading builder and developer company in
					Pakistan, known for delivering exceptional quality, innovation, and value to our clients. We
					strive to create innovative and sustainable communities that enhance the quality of life for
					our clients and contribute to the development of the country.
				</p>
				<p className="text-white font-work-sans text-xs md:text-sm lg:text-md font-normal">
					We aim to achieve our vision by staying at the forefront of the latest developments in the
					construction industry, leveraging cutting-edge technology and techniques to create
					beautiful, functional, and sustainable spaces. Our team is committed to maintaining the
					highest standards of integrity, transparency, and professionalism in everything we do,
					building long-term relationships with our clients based on trust and mutual respect.
					Ultimately, our goal is to create communities that people are proud to call home, where
					they can live, work, and thrive in an environment that supports their health, wellbeing, and
					happiness. We believe that by delivering exceptional quality and value, we can make a
					positive contribution to society and create a legacy that will endure for generations to
					come
				</p>
				<a href="#About" className="flex justify-center align-center items-center uppercase text-primary font-poppins bg-[#FFF] text-sm font-bold  px-[30px] py-[10px] leading-[100%] rounded-full">
					Learn More
				</a>
			</div>
		</div>
	);
}

export default Vision;
