import React, { useEffect } from "react";
import BagroundImage from "../assets/image 1.png";
import Building from "../assets/Polygon 1.png";
import { ReactComponent as Learn } from "../assets/chevron-down.svg";
import { ReactComponent as Started } from "../assets/Diagonal  Right Up Arrow.svg";

import AOS from "aos";
import "aos/dist/aos.css";
function Banner() {
	useEffect(() => {
		AOS.init();
	}, []);
	return (
		<div
			id="Home"
			className="w-full flex flex-row items-center justify-center bg-no-repeat bg-cover bg-center h-[90vh] 
      p-[0px 60px] xl:p-[0px 50px] lg:p-[0px 40px] md:p-[0px 30px] sm:p-[0px 20px] relative
"
			style={{
				background: `url('${BagroundImage}')`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover"
			}}
		>
			<div className="flex flex-col lg:w-[70%] md:w-[80%] w-[90%] absolute items-start justify-center left-[5%]">
				<h1 className="w-full text-white font-playfair text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-[900] md:leading-[110%] lg:leading-[120%] xl:leading-[143.284%]">
					Motiwala Builders
					<br /><span className="font-[300]">FROM BLUEPRINT TO REALITY</span>
				</h1>
				<p className="my-[2%] md:w-[80%] w-[100%] text-white font-poppins text-base md:text-sm lg:text-md xl:text-lg font-light leading-normal">
					Welcome to a world where your dreams take flight. At Motiwala Builders, we craft more than
					structures; we weave dreams into architectural wonders.
				</p>
				<div className="flex flex-row w-full items-start justify-start gap-10">

					<a
						href="#About"
						data-aos="zoom-out-right"
						data-aos-duration={500}
						className="cursor-pointer flex h-[60px] p-[16px] px-[25px] justify-center items-center gap-2 flex-shrink-0 rounded-[16px] bg-white text-[#215F8F] font-poppins text-base md:text-md lg:text-lg xl:text-xl font-normal leading-[177.778%]"
					>
						Get Started
						<Started />
					</a>
					<a
						href="#Projects"
						data-aos="zoom-out-right"
						data-aos-duration={500}
						className="cursor-pointer flex h-[60px] p-[16px] px-[25px] justify-center items-center gap-2 flex-shrink-0 rounded-[16px] bg-transparent text-white font-poppins text-base md:text-md lg:text-lg xl:text-xl font-normal leading-[177.778%]"
					>
						Learn More
						<Learn />
					</a>
				</div>
			</div>
			<div
				className="flex flex-col xl:w-[40%] lg:w-[40%] md:w-[60%] sm:w-[100%] h-[90vh] items-center justify-center pl-[5%]
				relative"
				data-aos="fade-right"
				data-aos-offset="300"
				data-aos-easing="ease-in-sine"
				data-aos-duration={2000}
			></div>
			<img
				data-aos="fade-up"
				data-aos-anchor-placement="center-bottom"
				data-aos-duration={1000}
				src={Building}
				className="hidden md:flex lg:w-[60%] xl:w-[60%] md:w-[40%] sm:w-[0%] h-[90vh]"
				alt="Building"
			/>
		</div>
	);
}

export default Banner;
